import React, {FC, useState} from "react";
import {Box, CardContent, List, ListItem, ListItemText, TextField, Typography,} from "@mui/material";
import { Card, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import Header from "../Functionalities/Objects/Header";
import BottomNavigation from "../Functionalities/Objects/BottomNavigation";
import {useUserContext} from "../Functionalities/Providers/userProvider";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {Item} from "../Functionalities/Objects/Item";
import { doc, deleteDoc, collection, setDoc, getDocs, updateDoc, writeBatch, query, where } from "firebase/firestore";
import { firestoreDB } from "../Functionalities/Firebase/config";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import {v4 as uuidv4} from "uuid";

export const CreateCustomTemplatePage: FC = () => {
    const {user} = useUserContext();
    const {travelId} = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState("");
    const [items, setItems] = useState<Item[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>("Miscellaneous");
    const [quantity, setQuantity] = useState<number>(1);
    const categories = ['Admin', 'Bathroom', 'Car','Clothes', 'Cooking','Hobby', 'Pharmacy', 'Sleeping', 'Technology', 'Tools'  ];
    const [showAddItem, setShowAddItem] = useState<boolean>(false);
    const [editingItemId, setEditingItemId] = useState<string | null>(null);
    const [editingQuantity, setEditingQuantity] = useState<number>(0);


    //Move to add/remove items page + Save the template
    const onGoToAddRemoveItem = () => {
        if (user) {
            navigate(`/${user.uid}/AddRemoveItemsPage/${travelId}`);
        }
    };

    //Needed to scroll to bottom of the page
    const bottomRef = React.useRef<HTMLDivElement>(null)

    //Show the form to enter manually a new item
    const onPlusButton = () => {
        setShowAddItem(true);
    };

    //Add the manually entered item to the list
    const onAddButton = async () => {
        if (!item || quantity <= 0) {
            alert("Please enter a valid item name and quantity.");
            return;
        }
        //New item with properties
        const newItem: Item = { itemId: uuidv4(), itemName: item, itemQuantity: quantity, itemCategory: selectedCategory, itemChecked: false };
        //Update the state of the new items
        setItems([...items, newItem]);
        //Reset the input fields
        setItem("");
        setQuantity(1);
        setSelectedCategory("Miscellaneous");
        setShowAddItem(false);

        if (user) {
            // Correctly referencing the items collection within a user's travel document
            const itemsCollectionRef = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            const itemDocRef = doc(itemsCollectionRef, newItem.itemId);
            await setDoc(itemDocRef, newItem);
        }
        console.log(newItem);
    };

    return  (
       <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{width:'100%'}}>
           <Header bottomRef={bottomRef}/>

           {/* Title */}
           <Box display="flex" justifyContent="center" alignItems="center" sx={{width:'100%', mb: 2}}>
               <Typography variant="h4" mt={1}>Create your own packing template!</Typography>
           </Box>

           {/* Template name */}
           <Box display="flex" justifyContent="center" alignItems="center" sx={{width:'100%', mb: 2}}>
            Template name
           </Box>

           {/*Form to add an item to the template*/}
           <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" sx={{width:'100%'}}>
               <Card variant="outlined" style={{border: '1px solid transparent', alignSelf: 'flex-start'}}>

                   <CardContent>

                       {/*Add item*/}
                       <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" sx={{width:'100%'}}>
                           Add item
                           <AddIcon onClick={onPlusButton} style={{cursor: 'pointer'}}/>
                       </Box>

                       {/*Form to add an item*/}
                       {showAddItem && (
                           <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" gap={2}>
                               <TextField variant="standard" label="Item name" value={item} onChange={(e) => setItem(e.target.value)}/>
                               <TextField variant="standard" label="Quantity" type="number" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))}/>
                               <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left" sx={{width:'100%'}}>
                                   <FormControl>
                                       <InputLabel id="category-label" >Category</InputLabel>
                                       <Select
                                           labelId="category-label"
                                           id="category-select"
                                           value={selectedCategory}
                                           label="Category"
                                           onChange={(event) => setSelectedCategory(event.target.value)}
                                       >
                                           {categories.map((category) => (
                                               <MenuItem key={category} value={category}>
                                                   {category}
                                               </MenuItem>
                                           ))}
                                       </Select>
                                   </FormControl>
                               </Box>

                               {/*Button to confirm the addition of the item*/}
                               <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="left">
                                   <Button onClick={onAddButton}>Add</Button>
                               </Box>
                           </Box>
                       )}
                   </CardContent>

               </Card>
           </Box>

           {/* Items list */}
           <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left" sx={{width:'100%'}}>
               {items.map((item) => (
                   <Box key={item.itemId} >
                       <ListItem divider>
                           <ListItemText primary={item.itemName}
                                         secondary={
                                             <React.Fragment>
                                                 <Typography component="span" variant="body2" color="textPrimary" sx={{mr:2}}>
                                                     {`Quantity: ${item.itemQuantity}`}
                                                 </Typography>
                                                 <Typography component="span" variant="body2" color="textPrimary" display="inline" sx={{mr:2, width:'100px'}}>
                                                     {`Category: ${item.itemCategory}`}
                                                 </Typography>
                                             </React.Fragment>}/>
                       </ListItem>
                   </Box>
               ))}
           </Box>


           {/* Go to AddRemoveItemPage */}
           <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" gap={2} sx={{marginTop:2,  width:'80%'}}>
               <Button variant="contained" color="primary" onClick={onGoToAddRemoveItem}>Next</Button>
           </Box>

           <BottomNavigation/>

       </Box>
   )
}