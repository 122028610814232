import {Box, CardContent, Typography, Checkbox, Divider, FormControlLabel} from "@mui/material";
import React, { FC } from "react";
import { Card } from '@mui/material';
import { Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "../Functionalities/Providers/userProvider";
import {useParams} from "react-router-dom";
import {useEffect, useState, useCallback} from "react";
import { Item } from "../Functionalities/Objects/Item";
import {collection, doc, getDocs, setDoc, deleteDoc, onSnapshot} from "firebase/firestore";
import { firestoreDB } from "../Functionalities/Firebase/config";
import Header from "../Functionalities/Objects/Header";
import BottomNavigation from "../Functionalities/Objects/BottomNavigation";


export const PackingPage: FC = () => {
    const {user} = useUserContext();
    const{travelId} = useParams();

    const[items, setItems] = useState<Item[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    useEffect(() => {
        if (!user) return;

        const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
        const checkedItemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/checkedItems`);

        const unsubscribeItems = onSnapshot(itemsCollection, (itemsSnapshot) => {
            let itemsList = itemsSnapshot.docs.map(doc => ({ ...doc.data(), itemId: doc.id } as Item));

            // Filter and sort by category
            if (selectedCategory) {
                itemsList = itemsList.filter(item => item.itemCategory === selectedCategory);
            }
            itemsList.sort((a, b) => a.itemCategory.localeCompare(b.itemCategory));

            onSnapshot(checkedItemsCollection, (checkedItemsSnapshot) => {
                const checkedItemIds = checkedItemsSnapshot.docs.map(doc => doc.id);

                itemsList = itemsList.map(item => ({
                    ...item,
                    itemChecked: checkedItemIds.includes(item.itemId),
                }));

                // Sort: Checked items last
                itemsList.sort((a, b) => a.itemChecked === b.itemChecked ? 0 : a.itemChecked ? 1 : -1);

                setItems(itemsList);
            });
        });

        return () => {
            unsubscribeItems();
        };
    }, [user, travelId, selectedCategory]);


    const handleCheck = async (itemId: string) => {
        if (!user) return;

        const itemIndex = items.findIndex(item => item.itemId === itemId);
        if (itemIndex === -1) return;

        // Create a shallow copy of the items
        const updatedItems = [...items];
        const targetItem = updatedItems[itemIndex];

        // Toggle the item's checked state
        const updatedItem = { ...targetItem, itemChecked: !targetItem.itemChecked };
        updatedItems[itemIndex] = updatedItem;

        // Update the state immediately
        setItems(updatedItems);

        // Firestore references
        const checkedItemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/checkedItems`);
        const uncheckedItemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/uncheckedItems`);
        const itemDocRefInCheckedItems = doc(checkedItemsCollection, itemId);
        const itemDocRefInUncheckedItems = doc(uncheckedItemsCollection, itemId);

        try {
            if (updatedItem.itemChecked) {
                // Add to checked items and remove from unchecked items
                await setDoc(itemDocRefInCheckedItems, updatedItem);
                await deleteDoc(itemDocRefInUncheckedItems);
            } else {
                // Add to unchecked items and remove from checked items
                await setDoc(itemDocRefInUncheckedItems, updatedItem);
                await deleteDoc(itemDocRefInCheckedItems);
            }
        } catch (error) {
            console.error("Error updating Firestore:", error);
            // Revert the change in case of an error
            setItems(items);
        }
    };


    const navigate = useNavigate();

    const onGoBackToAddRemoveItems = () => {
        if(user){
            navigate(`/${user.uid}/AddRemoveItemsPage/${travelId}`)
        }};
    const onGoToTravelDetails = () => {
        if(user){
            navigate(`/${user.uid}/TravelDetailsPage/${travelId}`)
        }};

    const bottomRef = React.useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Header bottomRef={bottomRef}/>

            {/* Title */}
            <Typography variant="h4" mt={1}>Let the packing begin!</Typography>

            {/* Categories */}
            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap" sx={{width:'100%'}}>

                <Box display="inline" flexDirection="column" justifyContent="center" sx={{width:'100%'}}>
                    <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center"  flexWrap="wrap" sx={{ border: '2px solid #DCDCDC', padding: '1px', borderRadius: '10px'}}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Admin'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Admin' : null)}/>
                        } label="Admin"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Bathroom'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Bathroom' : null)}/>
                        } label="Bathroom"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Car'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Car' : null)}/>
                        } label="Car"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Clothes'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Clothes' : null)}/>
                        } label="Clothes"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Cooking'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Cooking' : null)}/>
                        } label="Cooking"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Hobby'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Hobby' : null)}/>
                        } label="Hobby"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Pharmacy'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Pharmacy' : null)}/>
                        } label="Pharmacy"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Sleeping'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Sleeping' : null)}/>
                        } label="Sleeping"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Technology'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Technology' : null)}/>
                        } label="Technology"/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={selectedCategory === 'Tools'}
                                onChange={(event) => setSelectedCategory(event.target.checked ? 'Tools' : null)}/>
                        } label="Tools"/>
                    </Box>
                </Box>
            </Box>

            {/* Items list */}
            <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left">
                {items.map((item) => (
                    <Box key={item.itemId}>
                        <Card variant="outlined" style={{border: '1px solid transparent'}}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                <CardContent>
                                    <Checkbox checked={item.itemChecked} onChange={() => handleCheck(item.itemId)} />
                                </CardContent>
                                <CardContent style={{width:'55%'}}>
                                    <Typography variant="body1">{item.itemName}</Typography>
                                </CardContent>
                                <CardContent style={{width:'15%'}}>
                                    <Typography variant="body1">{item.itemQuantity}</Typography>
                                </CardContent>
                                <CardContent style={{width:'30%'}}>
                                    <Typography variant="body1">{item.itemCategory}</Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Divider/>
                    </Box>
                ))}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" gap={2} mt={3}>
                <Button variant="contained" color="primary" onClick={onGoBackToAddRemoveItems}>Back</Button>
                <Button variant="contained" color="primary" onClick={onGoToTravelDetails}>Next</Button>
            </Box>
            <Box ref={bottomRef}/>
            <BottomNavigation/>
        </Box>

    );
}