import {User, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";

import {FC, ReactNode, createContext, useContext, useEffect, useState,} from "react";

import { firebaseAuth } from "../Firebase/config";
import { firestoreDB } from "../Firebase/config";
import {doc, setDoc, getDoc, collection} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import {Travel} from "../Objects/Travel";


// Defines a typescript type that will be used by a userContext
export type UserContextType = {

    user?: User;

    // Checks if there is still data loading
    isLoading: boolean;

    // Used to signup a user
    signup: (
        email: string,
        password: string,
        firstname: string,
        lastname: string,
        travels: Travel[],
        navigate: (path:string) => void
    ) => Promise<{ user?: User; error?: string }>;

    // Used to sign in a user
    signin: (
        email: string,
        password: string
    ) => Promise<{ user?: User; error?: string }>;

    // User to logout a user
    logout: () => Promise<void>;

    //Used to update a user
    updateUser:(
        uid: string,
        data: {firstname: string, lastname: string, travels: Travel[]}
    ) => Promise<void>;

    // User to retrieve a user
    getUser: (uid: string) => Promise<{firstname:string; lastname:string; travels: Travel[]}>;

    //Used to save a travel to a user
    saveTravel:(userId:string, newTravel: Travel) => Promise<string>;
};

// userContext that uses the type defined above and implements default empty values
const UserContext = createContext<UserContextType>({
    signup: async () => ({}),
    signin: async () => ({}),
    logout: async () => {},
    isLoading: true,
    updateUser: async () => {},
    getUser: async () => ({firstname: "", lastname: "", travels: []}),
    saveTravel: async () => "",
});


// Real implementation of the userContext
export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {

    // Implementation of the User
    const [user, setUser] = useState<User | undefined>(undefined);

    // Implementation of the isLoading
    const [isLoading, setIsLoading] = useState(true);
 /*   const navigate = useNavigate(); */

    useEffect(() => {
        onAuthStateChanged(firebaseAuth, (user) => {
            setIsLoading(false);
            if (user) {
                setUser(user);
            } else {
                setUser(undefined);
            }
        });
    }, []);

    // Implementation of the signup function
    const signup = async (
        email: string,
        password: string,
        firstname: string,
        lastname: string,
        travels: Travel[],
        navigate: (path: string) => void
    ): Promise<{ user?: User; error?: string }> => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                firebaseAuth,
                email,
                password
            );

            // Updates the state of the user with the new user's data
            setUser(userCredential.user);

            //Extra information added to the user
            if (userCredential.user) {
                await setDoc(doc(firestoreDB, "users", userCredential.user.uid), {
                    firstname: firstname,
                    lastname: lastname,
                    travels: travels
                });

                //Navigate to the profile details page
                navigate(`/${userCredential.user.uid}/ProfileDetailsPage`);
            }

            return {user: userCredential.user};
        } catch (error) {
            console.error(error);

            return {error: (error as { message: string; code: string }).message};
        }
    };

    // Implementation of the signin function
    const signin = async (
        email: string,
        password: string
    ): Promise<{ user?: User; error?: string }> => {
        try {
            const userCredential = await signInWithEmailAndPassword(
                firebaseAuth,
                email,
                password
            );

            setUser(userCredential.user);

            return {user: userCredential.user};
        } catch (error) {
            console.error(error);

            return {error: (error as { message: string; code: string }).message};
        }
    };

    // Implementation of the logout function
    const logout = async () => {
        await firebaseAuth.signOut();
        setUser(undefined);
    };

    // Implementation of the updateUser function
    const updateUser = async (uid: string, data: {firstname: string, lastname: string, travels: Travel[]}) => {
        const userDocRef = doc(firestoreDB, "users", uid);
        await setDoc(userDocRef, data,{merge: true});
    };

    // Implementation of the getUser function
    const getUser = async (userId: string) => {
        const userDocRef = doc(firestoreDB, `users/${userId}`);
        const userDoc = await getDoc(userDocRef);
        return userDoc.exists() ? (userDoc.data() as { firstname: string; lastname: string; travels: Travel[]; }) : {
            firstname: "",
            lastname: "",
            travels: []
        };
    };


    // Implementation of the saveTravel property
    const saveTravel = async (userId: string, newTravel: Travel) => {
        // Correctly referencing the travels collection within a user's document
        const travelsCollectionRef = collection(firestoreDB, "users", userId, "travels");
        const travelDocRef = doc(travelsCollectionRef, newTravel.travelId);
        await setDoc(travelDocRef, newTravel);

        return newTravel.travelId;
    };

    //The value of the context
    const value: UserContextType = {user, isLoading, signup, signin, logout, updateUser, getUser, saveTravel};

        return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
    };

  /*  const findUserById = async (userId: string) => {
        const userDoc = await getDoc(doc(firestoreDB, "users", userId));
        if (userDoc.exists()) {
            return userDoc.data() as User;
        } else {
            console.error('User with ID ${userId} not found');
            return null;
        }
    } */

    export const useUserContext = (): UserContextType => {
        return useContext(UserContext);
    };

