import {Box, CardContent, List, ListItem, ListItemText, TextField, Typography,} from "@mui/material";
import React, {FC, useState, useEffect} from "react";
import { Card, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "../Functionalities/Providers/userProvider";
import { useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import {Item} from "../Functionalities/Objects/Item";
import { v4 as uuidv4 } from 'uuid';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { doc, deleteDoc, collection, setDoc, getDocs, updateDoc, writeBatch, query, where } from "firebase/firestore";
import { firestoreDB } from "../Functionalities/Firebase/config";
import Header from "../Functionalities/Objects/Header";
import BottomNavigation from "../Functionalities/Objects/BottomNavigation";
import standardItems  from "../Templates/standardItems.json";
import kidsItems  from "../Templates/kidsItems.json";
import carItems  from "../Templates/carItems.json";
import campingItems  from "../Templates/campingItems.json";
import cottageItems  from "../Templates/cottageItems.json";
import hikingItems  from "../Templates/hikingItems.json";
import beachItems  from "../Templates/beachItems.json";
import skiItems  from "../Templates/skiItems.json";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const AddRemoveItemsPage: FC = () => {
    const {user} = useUserContext();
    const {travelId} = useParams();
    const [showAddItem, setShowAddItem] = useState<boolean>(false);
    const [item, setItem] = useState<string>("");
    const [items, setItems] = useState<Item[]>([]);
    const [quantity, setQuantity] = useState<number>(1);
    const [editingItemId, setEditingItemId] = useState<string | null>(null);
    const [editingQuantity, setEditingQuantity] = useState<number>(0);
    const categories = ['Admin', 'Bathroom', 'Car','Clothes', 'Cooking','Hobby', 'Pharmacy', 'Sleeping', 'Technology', 'Tools'  ];
    const [selectedCategory, setSelectedCategory] = useState<string>("Miscellaneous");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchItems = async () => {
            if (user) {
                //Creates a Firebase collection
                const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
                //Fetches the documents of the collection
                const itemsSnapshot = await getDocs(itemsCollection);
                //Maps each document to an Item object containing the document data and the document ID
                let itemsList = itemsSnapshot.docs.map(doc => ({...doc.data(), itemId: doc.id} as Item));
                //Filter out duplicates
                itemsList = itemsList.filter((item, index, self) => index === self.findIndex((t) => (t.itemName === item.itemName)));
                console.log(itemsList);
                //Updates the state with the items list
                setItems(itemsList);
            }
        };

        //Call the async function
        fetchItems();

    }, [user, travelId]);

    //Show the form to enter manually a new item
    const onPlusButton = () => {
        setShowAddItem(true);
    };

    //Add the manually entered item to the list
    const onAddButton = async () => {
        if (!item || quantity <= 0) {
            alert("Please enter a valid item name and quantity.");
            return;
        }
        //New item with properties
        const newItem: Item = { itemId: uuidv4(), itemName: item, itemQuantity: quantity, itemCategory: selectedCategory, itemChecked: false };
        //Update the state of the new items
        setItems([...items, newItem]);
        //Reset the input fields
        setItem("");
        setQuantity(1);
        setSelectedCategory("Miscellaneous");
        setShowAddItem(false);

        if (user) {
            // Correctly referencing the items collection within a user's travel document
            const itemsCollectionRef = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            const itemDocRef = doc(itemsCollectionRef, newItem.itemId);
            await setDoc(itemDocRef, newItem);
        }
        console.log(newItem);
    };

    //Creates a new array of items excluding the item with itemId matching the parameter itemId
    const deleteItem = async (itemId: string) => {
        const updatedItems = items.filter((item) => item.itemId !== itemId);
        setItems(updatedItems);
        //And delete it from the firebase collections: from the main items, from the checked items and from the unchecked items
        if (user) {
            const docRef = doc(firestoreDB, `users/${user.uid}/travels/${travelId}/items/${itemId}`);
            await deleteDoc(docRef);

            const checkedItemDocRef = doc(firestoreDB, `users/${user.uid}/travels/${travelId}/checkedItems/${itemId}`);
            await deleteDoc(checkedItemDocRef);

            const uncheckedItemDocRef = doc(firestoreDB, `users/${user.uid}/travels/${travelId}/uncheckedItems/${itemId}`);
            await deleteDoc(uncheckedItemDocRef);

        }
    };

    //Edit an item of the list
    const startEditing = (itemId: string, itemQuantity: number) => {
        setEditingItemId(itemId);
        setEditingQuantity(itemQuantity);
    }

    //Stop editing an item of the list
    const stopEditing = () => {
        setEditingItemId(null);
    }

    //Update the quantity of an item
    const updateQuantity = async (itemId: string, newQuantity: number) => {
        if (user) {
            const docRef = doc(firestoreDB, `users/${user.uid}/travels/${travelId}/items/${itemId}`);
            await updateDoc(docRef, {itemQuantity: newQuantity});

            //Update the local state
            setItems(items.map(item => item.itemId === itemId ? {...item, itemQuantity: newQuantity} : item));
        }
    }

    // //Add a custom template
    // const onAddCustomTemplate = () => {navigate(`/${user?.uid}/CreateCustomTemplatePage/${travelId}`)};

    //Add the items of the STANDARD template
    const onAddTemplateStandardItems = async () => {
        console.log("Adding hotel items");
        //Iterate over each item of the kidsItems array and create a new array of items with the same properties
        const newItems = standardItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));


        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Add the items of the KIDS template
    const onAddTemplateKidsItems = async () => {
        console.log("Adding kid items");
        //Iterate over each item of the kidsItems array and create a new array of items with the same properties
        const newItems = kidsItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));


        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Add the items of the CAR template
    const onAddTemplateCarItems = async () => {
        console.log("Adding car items");
        //Iterate over each item of the carItems array and create a new array of items with the same properties
        const newItems = carItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));


        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Add the items of the CAMPING template
    const onAddTemplateCampingItems = async () => {
        console.log("Adding camping items");
        //Iterate over each item of the campingItems array and create a new array of items with the same properties
        const newItems = campingItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));

        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Add the items of the COTTAGE template
    const onAddTemplateCottageItems = async () => {
        console.log("Adding cottage items");
        //Iterate over each item of the cottageItems array and create a new array of items with the same properties
        const newItems = cottageItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));


        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Add the items of the HIKING template
    const onAddTemplateHikingItems = async () => {
        console.log("Adding cottage items");
        //Iterate over each item of the hikingItems array and create a new array of items with the same properties
        const newItems = hikingItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));


        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Add the items of the BEACH template
    const onAddTemplateBeachItems = async () => {
        console.log("Adding beach items");
        //Iterate over each item of the beachItems array and create a new array of items with the same properties
        const newItems = beachItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));

        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Add the items of the SKI template
    const onAddTemplateSkiItems = async () => {
        console.log("Adding ski items");
        //Iterate over each item of the skiItems array and create a new array of items with the same properties
        const newItems = skiItems.map(item => ({
            ...item,
            itemId: uuidv4(), // Generate a new unique ID for each item
            itemCategory: item.itemCategory||"", //If no item category, empty string
        }));

        // Add the new items to Firebase
        if (user) {
            const itemsCollection = collection(firestoreDB, `users/${user.uid}/travels/${travelId}/items`);
            for (const item of newItems) {
                //Check if the item already exists in the collection
                const itemSnapshot = await getDocs(query(itemsCollection, where("itemName", "==", item.itemName)));
                if (itemSnapshot.empty) {
                    //If the item doesn't exist, add it to the collection
                    const itemDocRef = doc(itemsCollection, item.itemId);
                    await setDoc(itemDocRef, item);
                }
            }
        }

        // Update the local state
        setItems(prevItems => [...prevItems, ...newItems.filter(item => !prevItems.find(prevItem => prevItem.itemName === item.itemName))]);
    };

    //Move to Packing page
    const onGoToPacking = () => {
        if (user) {
            navigate(`/${user.uid}/PackingPage/${travelId}`);
        }
    };

    //Needed to scroll to bottom of the page
    const bottomRef = React.useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{width:'100%'}}>
            <Header bottomRef={bottomRef}/>

            {/* Title */}
            <Box display="flex" justifyContent="center" alignItems="center" sx={{width:'100%', mb: 2}}>
                <Typography variant="h4" mt={1}>What do you want to pack?</Typography>
            </Box>

            {/* Categories */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{width:'100%'}}>
                <Box>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateStandardItems} sx={{mr:2, mb:2}}>Start list</Button>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateKidsItems} sx={{mr:2, mb:2}}>+ Kids</Button>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateCarItems} sx={{mr:2, mb:2}}>+ Car</Button>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateCampingItems} sx={{mr:2, mb:2}}>+ Camping</Button>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateCottageItems} sx={{mr:2, mb:2}}>+ Cottage</Button>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateHikingItems} sx={{mr:2, mb:2}}>+ Hiking</Button>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateBeachItems} sx={{mr:2, mb:2}}>+ Beach</Button>
                    <Button variant="outlined" color="primary" onClick={onAddTemplateSkiItems} sx={{mr:2, mb:2}}>+ Ski</Button>
                    {/*  <Button variant="contained"  onClick={onAddCustomTemplate} sx={{mr:2, mb:2}}>Create custom template</Button> */}
                </Box>

                {/* Additional item */}
                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" sx={{width:'100%'}}>
                    <Card variant="outlined" style={{border: '1px solid transparent', alignSelf: 'flex-start'}}>

                        <CardContent>

                            {/*Add item*/}
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" sx={{width:'100%'}}>
                            Add item
                            <AddIcon onClick={onPlusButton} style={{cursor: 'pointer'}}/>
                            </Box>

                            {/*Form to add an item*/}
                            {showAddItem && (
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" gap={2}>
                                    <TextField variant="standard" label="Item" value={item} onChange={(e) => setItem(e.target.value)}/>
                                    <TextField variant="standard" label="Quantity" type="number" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))}/>
                                    <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left" sx={{width:'100%'}}>
                                        <FormControl>
                                            <InputLabel id="category-label" >Category</InputLabel>
                                            <Select
                                                labelId="category-label"
                                                id="category-select"
                                                value={selectedCategory}
                                                label="Category"
                                                onChange={(event) => setSelectedCategory(event.target.value)}
                                            >
                                                {categories.map((category) => (
                                                    <MenuItem key={category} value={category}>
                                                        {category}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    {/*Button to confirm the addition of the item*/}
                                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="left">
                                        <Button onClick={onAddButton}>Add</Button>
                                    </Box>
                                </Box>
                            )}
                        </CardContent>

                    </Card>
                </Box>

                {/* Items list */}
                <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left" sx={{width:'100%'}}>
                    {items.map((item) => (
                        <Box key={item.itemId} >
                            <ListItem divider>
                                <ListItemText primary={item.itemName}
                                              secondary={
                                                  <React.Fragment>
                                                      <Typography component="span" variant="body2" color="textPrimary" sx={{mr:2}}>
                                                          {`Quantity: ${item.itemQuantity}`}
                                                      </Typography>
                                                      <Typography component="span" variant="body2" color="textPrimary" display="inline" sx={{mr:2, width:'100px'}}>
                                                          {`Category: ${item.itemCategory}`}
                                                      </Typography>
                                                  </React.Fragment>}/>
                                {editingItemId === item.itemId ? (
                                    <div>
                                        <Typography variant="caption">Edit quantity </Typography>
                                        <TextField
                                            type="number"
                                            value={editingQuantity}
                                            onChange={(e) => setEditingQuantity(Number(e.target.value))}
                                        />
                                        <Button onClick={() => {
                                            updateQuantity(item.itemId, editingQuantity);
                                            stopEditing();
                                        }}>Save</Button>
                                    </div>
                                ) : (
                                    <ModeEditIcon onClick={() => startEditing(item.itemId, item.itemQuantity)}/>
                                )}
                                <DeleteSweepIcon onClick={() => deleteItem(item.itemId)} style={{cursor: 'pointer'}}/>
                            </ListItem>
                        </Box>
                    ))}
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" gap={2} sx={{marginTop:2,  width:'80%'}}>
                    <Button variant="contained" color="primary" onClick={onGoToPacking}>Next</Button>
                </Box>
            </Box>
            <Box ref={bottomRef}/>
            <BottomNavigation/>
        </Box>
    );
};