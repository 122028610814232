import { Box, CircularProgress } from "@mui/material";
import { FC, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserContext } from "../Providers/userProvider";

export const PrivateRoute: FC = () => {

    // Needed for the useEffect
    const { user, isLoading } = useUserContext();

    //Needed for the useEffect
    const navigate = useNavigate();

    //Navigate to /login page if user is null or undefined AND if data is not loading anymore
    useEffect(() => {
        if (!user && !isLoading) {
            navigate("/login", { replace: true });
        }
    }, [user, navigate, isLoading]);

    // Shows the circular progress if data is still loading
    if (isLoading) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box height="100vh" display="flex" flexDirection="column">
            <Box flexGrow={1} overflow="auto">
                <Outlet />
            </Box>
        </Box>
    );
};
