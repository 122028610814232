import {Box, CardContent, TextField, Typography} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import { Card } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "../../src/Functionalities/Providers/userProvider";
import {Travel} from "../Functionalities/Objects/Travel";
import Header from "../Functionalities/Objects/Header";

export const ProfileDetailsPage: FC = () => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [travels, setTravels] = useState<Travel[]>([]);
    const{user, updateUser, getUser} = useUserContext();

    const navigate = useNavigate();

    //Fetch user data when user state changes
    useEffect(()=>{
        if(user){
            console.log('User:', user);
            console.log('User ID:', user.uid);
            getUser(user.uid).then((data)=> {
                if(data.firstname !== "firstname"){ setFirstname(data.firstname);}
                if(data.lastname !== "lastname"){ setLastname(data.lastname);}
                if(data.travels){setTravels(data.travels)}else{setTravels([]);}
                console.log('Travels:', data.travels);
            });
            }
        }, [user, getUser]);

    const onSave=()=>{
        if(user){
            updateUser(user.uid, {firstname, lastname, travels});
        }
        navigate("/${user.uid}/OverviewTravelsPage");
    }

    const bottomRef = React.useRef<HTMLDivElement>(null);

    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Header bottomRef={bottomRef}/>
            <Box>
                <Typography variant="h4" mt={5}>Profile Details</Typography>
            </Box>

          <Box sx={{width:'80%'}}>
            <Card variant="outlined" style={{border: '1px solid transparent'}}>
                <CardContent>

                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="h6" mr={2}>Firstname</Typography>
                      <TextField variant="standard" value={firstname} onChange={(e) => setFirstname(e.target.value)} placeholder=""></TextField>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="center" mt={3}>
                      <Typography variant="h6" mr={2}>Lastname</Typography>
                      <TextField variant="standard" value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder=""></TextField>
                    </Box>

                </CardContent>

                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2} mt={3}>
                    <Button variant="contained" color="primary" onClick={onSave}>Save</Button>
                </Box>

            </Card>
          </Box>
          <Box ref={bottomRef}/>
      </Box>
  );
}