import {Box, CardContent, TextField, Typography} from "@mui/material";
import React, {FC, useState} from "react";
import { Card } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useUserContext} from "../Functionalities/Providers/userProvider";
import {Travel} from "../Functionalities/Objects/Travel";
import { v4 as uuidv4 } from 'uuid';
import Header from "../Functionalities/Objects/Header";
import BottomNavigation from "../Functionalities/Objects/BottomNavigation";


export const CreateNewTravelPage: FC = () => {
    const [destination, setDestination] = useState("");
    const [departureDate, setDepartureDate] = useState("");
    const {user, saveTravel, getUser, updateUser} = useUserContext();

    const navigate = useNavigate();

    const onGoBackOverview = () => {
        if(user) {
            navigate(`/${user.uid}/OverviewTravelsPage`)
        }};

    const onSaveTravel = async() => {
        // Check if the destination and departure date fields are not empty
        if (!destination || !departureDate) {
            alert("Both destination and departure date are required.");
            return;
        }
        console.log("Call the onSaveTravel function");
        if(!user){
            console.error("User not logged in");
            return;
        }
        const newTravel: Travel = {
            travelId: uuidv4(),
            destination: destination,
            departureDate: departureDate
        };

        const travelId = await saveTravel(user.uid, newTravel);
        console.log('Travel saved with id:', travelId);

        //Retrieve the user's data
        const userData = await getUser(user.uid);
        console.log('Retrieved user data:', userData);

        //Initialize array empty if it doesn't exist
        if(!userData.travels){
            userData.travels = [];
        }
        //Add the new travel to the user's data (local, not yet in external DB)
        userData.travels.push(newTravel);
        console.log('Updated travels array:', userData.travels);

        //Update the user's travel array (in Firestore)
        await updateUser(user.uid, userData);
        console.log('User data updated:', userData);

        //Navigate to the next step
        navigate(`/${user.uid}/AddRemoveItemsPage/${travelId}`);
    };

    //Needed to scroll to bottom of the page
    const bottomRef = React.useRef<HTMLDivElement>(null);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Header bottomRef={bottomRef}/>
            <Typography variant="h4" mt={5}>Start a new travel</Typography>
            <Box sx={{width:'80%'}}>
                <Box mt={3}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="h6" mr={6}>Destination</Typography>
                                <TextField variant="standard" onChange={(e) => setDestination(e.target.value)} placeholder=""></TextField>
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="left" mt={3} sx={{width:'30%'}}>
                                <Typography variant="h6" mr={2}>Departure date</Typography>
                                <TextField variant="standard" type="date" onChange={(e) => setDepartureDate(e.target.value)} placeholder=""></TextField>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2} mt={3}>
                    <Button variant="contained" color="primary" onClick={onGoBackOverview}>Back</Button>
                    <Button variant="contained" color="primary" onClick={onSaveTravel}>Next</Button>
                </Box>
            </Box>
            <Box ref={bottomRef}/>
            <BottomNavigation/>
        </Box>
    );
}