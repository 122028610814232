import { Box, Button, TextField, Typography } from "@mui/material";
import {FC, useEffect, useReducer, useState} from "react";
import { useUserContext } from "../../src/Functionalities/Providers/userProvider";
import { useNavigate } from "react-router-dom";
import {Travel} from "../Functionalities/Objects/Travel";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import logoCheckIt from "../logoCheckIt.jpg";
import { useMediaQuery, useTheme } from '@mui/material';


export const Homepage: FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { user, signup, signin, logout } = useUserContext();
    const navigate = useNavigate();

   // const { logout } = useUserContext();

    const theme= useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        //Is the user truthy (not null, not undefined, not false)
        if (user) {
            //Navigate to the new page and the user can't go return to the current page using the back button
            navigate("/", { replace: true });
        }
        //Dependency of the useEffect: the use effect is called when a value of this array changes
    }, [user, navigate]);

    const auth = getAuth();

    const registerUser = async (username: string, password: string) => {

        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate the username
        if (!emailRegex.test(username)) {
            alert("Please enter a valid email address.");
            return null;
        }

        // Validate the password
        if (password.length < 6) {
            alert("Password must be at least 6 characters long.");
            return null;
        }

        try {
            // Create a new user with Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, username, password);
            // The user has been created successfully
            console.log("User created: ", userCredential.user);
            return userCredential;
        } catch (error) {
            // An error occurred while creating the user
            let errorMessage = '';
            const errorCode = (error as any).code; // Type assertion
            switch (errorCode) {
                case 'auth/email-already-in-use':
                    errorMessage = 'This email is already in use';
                    break;
                case 'auth/invalid-email':
                    errorMessage = 'The email address is not valid.';
                    break;
                default:
            }
            alert(errorMessage); // Display the custom error message in an alert
            return null;
        }
    };

   //Async = returns a promise (can use await in the function)
    const onSignup = async () => {
        //registerUser returns the userCredential with parameters email and password
        //Await pauses the execution of the function until the promise is resolved
        const userCredential = await registerUser(email, password);
        //If user Credential & userCredential.user exist, navigate to the profile details page
        if (userCredential && userCredential.user) {
            navigate(`/${userCredential.user.uid}/ProfileDetailsPage`);
        }
    };

    const onSignin = async () => {
        const result = await signin(email, password);

        if (result.error) {
            alert("Please enter a correct email and password and try again")
            console.error(result.error);
        }
        if (result.user) {
            navigate("/${user.uid}/OverviewTravelsPage");
        }
    };

    return (
        <Box display="flex" flexDirection={{xs: 'column', sm:'row'}} justifyContent="center" alignItems="center" height="100vh">

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h5">CheckIt</Typography>
                <img src={logoCheckIt} alt="CheckIt logo" style={{width: isMobile? '80%' : '60%', height:'auto'}}/>
            </Box>

            <Box sx={{width:'80%'}} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" flexDirection="column" maxWidth="500px" width={1} padding={2} gap={2}>
                    <TextField variant="outlined" label="Email" value={email} onChange={(event) => setEmail(event.target.value)}/>
                    <TextField variant="outlined" label="Password" type="password" value={password} onChange={(event) => setPassword(event.target.value)}/>
                    <Box display="flex" gap={2}>
                        <Button variant="contained" color="primary" fullWidth onClick={onSignup}>Register</Button>
                        <Button variant="contained" color="primary" fullWidth onClick={onSignin}>Login</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
